import React from "react";

const DBFooter = () => {
  return (
    <div className="flex items-center justify-center py-4 text-sm theme-primary-color-bg">
      <span className="text-white font-medium text-sm md:text-md lg:text-lg">
        কপিরাইট ©2023 আইটেসারেক্ট টেকনোলিজস। সর্বস্বত্ব সংরক্ষিত।
      </span>
    </div>
  );
};

export default DBFooter;
